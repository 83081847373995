<template>
  <basic-layout header="VMGO">
    <div class="bg-white bg-opacity-90">
      <container class="py-20 px-4">
        <section class="hidden sm:block">
          <div class="flex justify-center p-20">
            <div class="w-96 h-96 rounded-full border-2 border-green-800 relative">
              <div class="flex justify-center absolute -top-28 w-full">
                <div class="rounded-full bg-green-700 w-56 h-56 flex justify-center items-center shadow-2xl uppercase m-6">
                  <h1 class="text-white text-4xl py-2">Vision</h1>
                </div>
              </div>
              <div class="flex justify-center items-center h-full -left-48 absolute w-full">
                <div class="rounded-full bg-blue-400 w-56 h-56 flex justify-center items-center shadow-2xl uppercase m-6">
                  <h1 class="text-white text-4xl py-2">Mission</h1>
                </div>
              </div>
              <div class="flex justify-center items-center h-full -right-48 absolute w-full">
                <div class="rounded-full bg-pink-500 w-56 h-56 flex justify-center items-center shadow-2xl uppercase m-6">
                  <h1 class="text-white text-4xl py-2">Goal</h1>
                </div>
              </div>
              <div class="flex justify-center absolute -bottom-28 w-full">
                <div class="rounded-full bg-yellow-500 w-56 h-56 flex justify-center items-center shadow-2xl uppercase m-6">
                  <h1 class="text-white text-4xl py-2">Objectives</h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="my-20 relative">
          <div class="z-10 ">
            <div class="sm:grid sm:grid-cols-2 my-4">
              <div class="col-span-1 rounded-xl overflow-hidden m-4 border-2 border-green-700 bg-white h-full m-6">
                <div class="flex justify-center bg-green-700 text-white p-2">
                  <h1 class="text-5xl font-semibold">Vision</h1>
                </div>
                <div class="p-4">
                  <p class="text-xl">
                    A well- equipped library with  adequate library materials that effectively compliment instruction and research necessary for the wholesome development of individuals who are values- oriented and economically productive skilled workers , technicians and professionals.
                  </p>
                </div>
              </div>
              <div class="col-span-1 rounded-xl overflow-hidden m-4 border-2 border-blue-400 bg-white h-full m-6">
                <div class="flex justify-center bg-blue-400 text-white p-2">
                  <h1 class="text-5xl font-semibold">Mission</h1>
                </div>
                <div class="p-4">
                  <p class="text-xl">
                    To provide relevant, enriched and updated library collections in support to the major functions such as instructions, research, extension and production.
                  </p>
                </div>
              </div>
            </div>
            <div class="sm:grid sm:grid-cols-2 my-4">
              <div class="col-span-1 rounded-xl overflow-hidden m-4 border-2 border-pink-500 bg-white h-full m-6">
                <div class="flex justify-center bg-pink-500 text-white p-2">
                  <h1 class="text-5xl font-semibold">Goal</h1>
                </div>
                <div class="p-4">
                  <p class="text-xl">
                    To serve effectively and efficiently the greatest number of clienteles through access to varied information sources.
                  </p>
                </div>
              </div>
              <div class="col-span-1 rounded-xl overflow-hidden m-4 border-2 border-yellow-500 bg-white h-full m-6">
                <div class="flex justify-center bg-yellow-500 text-white p-2">
                  <h1 class="text-5xl font-semibold">Objectives</h1>
                </div>
                <div class="p-4 text-xl">
                  <p class="">
                    To provide adequate, well selected, and well organized library collections supporting the various curricular offerings of the College.
                  </p>
                  <p>1. To instill among students the love for books and reading and encourage the habit of personal investigation and research.</p>
                  <p>2. To promote linkage network with other libraries and agencies for exchange and enrichment of library resources.</p>
                  <p>3. To select, acquire, organize and preserve library materials for the use of the students, faculty, researchers, and other professionals.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </container>
    </div>
  </basic-layout>
</template>

<script>
import BasicLayout from "../layouts/BasicLayout";
import Container from "../layouts/Container";
export default {
  name: "VMGO",
  components: {Container, BasicLayout}
}
</script>

<style scoped>

</style>